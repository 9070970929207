const dropdown = () => {
    const $dropdownToggle = $('.dropdown-toggle');
    let flag = false;

    const resetDefault = e => {
        
      e.stopPropagation();
      e.preventDefault();
    };

    $dropdownToggle.on('click', function (e) {
        const $this = $(this);
        $(this).parent().siblings('li').removeClass('show');
        $(this).parent().toggleClass('show');
        $(this).siblings().removeClass('show');
        const $subMenu = $this.next('.sub-menu');

        if ($subMenu.length) {
            resetDefault(e);
        }
    });
};

export { dropdown };