require("slick-carousel");
class SlickCarousel {
  constructor(sliderWrap) {
    this.sliderWrap = sliderWrap;
  }

  initiateCarouselContainer() {
    $(document).ready(() => {
      $(`${this.sliderWrap}`).slick({
        slidesToShow: 3,
        nextArrow:
          '<span class="material-icons slick--next">chevron_right</span>',
        prevArrow:
          '<span class="material-icons slick--prev">chevron_left</span>',
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              centerMode: false,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }
  initiateCarouselFullWidth() {
    $(document).ready(() => {
      $(`${this.sliderWrap}`).slick({
        slidesToShow: 2,
        centerMode: true,
        arrows: true,
        slidesToScroll: 1,
        dots: true,
        nextArrow: '<span class="material-icons fake-next_opinion">chevron_left</span>',
        prevArrow: '<span class="material-icons fake-prev_opinion">chevron_left</span>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              centerMode: false,
              slidesToScroll: 1,
              dots: false,
              centerMode: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              dots: false,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }
  fakeArrow(fakeArrowProp, realArrow) {
    $(document).on('click', `${fakeArrowProp}`, () => {
      $(`${realArrow}`).click();
    })

  }
}
  const offerSlider = new SlickCarousel("#carousel-offer");
  const opinionSlider = new SlickCarousel(".slider-wrapper");

export { offerSlider, opinionSlider };