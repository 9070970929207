class MainPage {
  constructor(
    openMenu,
    closeMenu,
    menuWidth,
    menuWrapper,
    playVideoBTN,
    videoToPlay,
    mobileToggler,
    mobileMenu,
    navIcon,
  ) {
    this.openMenu = openMenu;
    this.closeMenu = closeMenu;
    this.menuWidth = menuWidth;
    this.menuWrapper = menuWrapper;
    this.playVideoBTN = playVideoBTN;
    this.videoToPlay = videoToPlay;
    this.mobileToggler = mobileToggler;
    this.mobileMenu = mobileMenu;
    this.navIcon = navIcon;
  }

  menuToggleClass() {
    const element = document.querySelector(`${this.menuWrapper}`);
    element.classList.toggle("menu-width");
  }
  toggleMenu(element) {
    const btn = document.querySelector(`${element}`);
    btn.addEventListener("click", () => {
      this.menuToggleClass();
    });
  }
  playMainVideo() {
    if (document.querySelector(`${this.playVideoBTN}`) != null) {
    const btn = document.querySelector(`${this.playVideoBTN}`)
    btn.addEventListener('click', () => {
       const videoPlayer =  document.querySelector(`${this.videoToPlay}`);
       videoPlayer.play();
       videoPlayer.classList.add('video-height');
       
        btn.style.display = 'none';
    })
  }
  }
  mobileMenuTrigger() {
    const animation = document.querySelector(`${this.navIcon}`)
    animation.classList.toggle('open');
    $(`${this.mobileMenu}`).slideToggle();
  }
  mobileMenuToggler() {
    const btn = document.querySelector(`${this.mobileToggler}`);
    btn.addEventListener('click', () => {

      this.mobileMenuTrigger();
    })
  }
}
const main_menu = new MainPage(
  ".menu-expand",
  "#desktop-menu .close-left-menu",
  "25",
  "#desktop-menu #left-menu",
  ".video-overlay",
  ".promote-video",
  ".menu-expand-mobile",
  '.main-menu',
  "#nav-icon3",
);

function init_menu() {
  main_menu.toggleMenu(main_menu.openMenu);
  main_menu.toggleMenu(main_menu.closeMenu);
  main_menu.playMainVideo();
  main_menu.mobileMenuToggler();
}

export { init_menu };
