// Style

import "../sass/main.scss";
import "./functions/main-page";
import { offerSlider, opinionSlider } from "./carousel/index";
import { init_menu } from "./functions/main-page";
import { dropdown } from './dropdown/dropdown';
import lightbox from "lightbox2";

$(document).ready(() => {
  init_menu();
  offerSlider.initiateCarouselContainer();
  opinionSlider.initiateCarouselFullWidth();
  dropdown();
  offerSlider.fakeArrow(
    "#section-opinion .slick--next_opinion",
    "#section-opinion .fake-next_opinion"
  );
  offerSlider.fakeArrow(
    "#section-opinion .slick--prev_opinion",
    "#section-opinion .fake-prev_opinion"
  );

  opinionSlider.fakeArrow(
    "#section-blog .slick--prev_opinion",
    "#section-blog .fake-prev_opinion"
  );
  opinionSlider.fakeArrow(
    "#section-blog .slick--next_opinion",
    "#section-blog .fake-next_opinion"
  );

  $("#menu-header-menu .depth_1 a").on("click", function (e) {
    window.location.href = $(this).attr("href");
  });
  $("#menu-header-menu-ang .depth_1 a").on("click", function (e) {
    window.location.href = $(this).attr("href");
  });
  $(document).on("click", ".gallery-item_wrap", function () {
    $(this).children(".special-class-display").click();
  });

  $("[data-fancybox]").fancybox({

      thumbs: {
        autoStart: true,
      },
  });

  $('.promote-video').on('click', function() {
    $(this).toggleClass('video-height');
  })

});
$('html').click(function(event) {
  if ($(event.target).closest('#left-menu, .menu-expand').length === 0) {
      $('.left-menu-desktop').removeClass('menu-width');
  }
})



$('html').on('click', function(){
  var mainElement = $('#menu-header-menu');
  if($(this).closest(mainElement).length){
      return;
  }
  $('.nav-item').removeClass('show');
}) 